import React from "react";
import BasePage from "../components/base-page";
import styled from "styled-components";
import Navigation from "../components/navigation";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const StyledIFrame = styled.iframe`
  width: 100vw;
  flex: 1;
`

const Demo = (props) => (
  <BasePage {...props}>
    <Container>
      <Navigation />
      <StyledIFrame src={process.env.GATSBY_DEMO_URL} title="demo-embed" frameBorder={0} />
    </Container>
  </BasePage>
)

export default Demo;
